import React, { useEffect, useState } from 'react'
import { units } from './models'
import { FaSearch } from 'react-icons/fa'
import ReactGA from 'react-ga4'

ReactGA.initialize('G-E68TZ3CBPD')

const HowDiagnose = () => {
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/diabetes-how-diagnose',
      title: 'Como Diagnosticar Diabetes'
    })
  }, [])

  const filteredUnits = units.filter((unit) =>
    Object.values(unit).some((value) =>
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  )

  return (
    <div className="container-page diabetes">
      <div className="content-page">
        <div className="header">
          <h1>COMO DIAGNOSTICAR</h1>
          <p>
            Os exames podem ser feitos em uma das{' '}
            <b>108 Unidades de Saúde de Curitiba.</b>
          </p>
          <p>
            Para acessar o serviço basta agendar na recepção da US mais próxima
            ou pelo App Saúde Já Curitiba
          </p>
        </div>

        <div className="search-container">
          <FaSearch className="search-icon" />
          <input
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="search-input"
          />
        </div>
        <div className="list-units">
          {filteredUnits.map((unit) => (
            <div className="unit-card" key={unit.name}>
              <div className="title-unit">
                <h2>{unit.name}</h2>
              </div>
              <div className="content-unit">
                <p>{unit.address}</p>
                <p>{unit.phone}</p>
                <p>{unit.businessHours}</p>
                <div className="content-button-unit">
                  <a
                    href={`https://www.google.com/maps/dir//${unit.address}`}
                    target="_blank"
                    className="button-unit"
                  >
                    Ir até o local
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HowDiagnose
