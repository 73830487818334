export const units = [
  {
    name: 'US Bairro Novo',
    phone: '3289-6877',
    address: 'Rua Paulo Rio Branco de Macedo, 791 - Sítio Cercado',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Coqueiros',
    phone: '3286-1514 e 3286-1589',
    address: 'Rua Coronel Victor Agner Kendrick, 80 Sitio Cercado',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US João Cândido',
    phone: '3289-5628 e 3289-1727',
    address: 'Rua Ourizona, 2250',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Nossa Senhora Aparecida',
    phone: '3289-6252 e 3289-1876',
    address: 'Rua Carlos Amoretty Osório, 169 - Sítio Cercado',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Osternack',
    phone: '3289-4311 e 3289-2246',
    address: 'Rua Miguel Rossetim, 100 - Vila Osternack',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Parigot de Souza',
    phone: '3378-6467 e 3378-2001',
    address: 'Rua João Eloy de Souza, 111 - Sítio Cercado',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Salvador Allende',
    phone: '3227-4059 e 3227-1781',
    address: 'Rua Celeste Tortato Gabardo, 1712 - Sitio Cercado',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Sambaqui',
    phone: '3298-6235 e 3298-6234',
    address: 'Rua Roberto Dala Barba, 44 - Sítio Cercado',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US São João Del Rey',
    phone: '3227-4061 e 3349-1765',
    address: 'Rua Realeza, 259',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Umbará I',
    phone: '3348-5013 e 3348-2575',
    address: 'Rua Dep. Pinheiro Junior, 915 - Umbará',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Umbará II',
    phone: '3313-5860',
    address: 'Rua Estanislau Selenko, 365 - Umbará',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Xapinhal',
    phone: '3227-4138 e 3227-4276',
    address: 'Rua Elbe de Macedo, 100 - Sítio Cercado',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Érico Veríssimo',
    phone: '3378-2018',
    address:
      'Rua Expedicionário Francisco Pereira dos Santos, 510 - Alto Boqueirão',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Esmeralda',
    phone: '3217-1288',
    address: 'Rua Coronel Rivadávia Pereira Moraes, 431 - Xaxim',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Eucaliptos',
    phone: '3286-1738',
    address: 'Rua Lázaro Borsato, 150 - Alto Boqueirão',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Irmã Teresa Araújo',
    phone: '3286-1337',
    address: 'Rua Maestro Carlos Frank, 785 - Boqueirão',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Jardim Paranaense',
    phone: '3378-1661',
    address: 'Rua Pedro Nabosne 57 - Alto Boqueirão',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Menonitas',
    phone: '3217-1294',
    address: 'Rua Domicio da Costa, 52 - Xaxim',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Moradias Belém',
    phone: '3286-1750',
    address: 'Rua Cezinando Dias Paredes, 1096 - Boqueirão',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Pantanal',
    phone: '3379-7347',
    address: 'Rua Maria Marques de Camargo, 119 - Alto Boqueirão',
    businessHours: 'Segunda a sexta das 8h às 17h'
  },
  {
    name: 'US São Pedro',
    phone: '3212-1546',
    address: 'Rua Bernardo Mann 131 - Xaxim',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Tapajós',
    phone: '3217-1241',
    address: 'Rua André Ferreira de Camargo, 188 - Xaxim',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Vila Hauer',
    phone: '3217-1283',
    address: 'Rua Waldemar Kost, 650 - Hauer',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Visitação',
    phone: '3286-1338',
    address: 'Rua Bley Zorning, 3136 - Boqueirão',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Waldemar Monastier',
    phone: '3217-1279',
    address: 'Rua Romeu Bach, 80 - Boqueirão',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Xaxim',
    phone: '3212-1543',
    address: 'Rua Batista da Costa, 1163 - Xaxim',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Alvorada',
    phone: '3575-1773',
    address: 'Rua Ivone do Espírito Santo Garcia, 100 - Uberaba',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Cajuru',
    phone: '3361-2343',
    address: 'Rua Pedro Bochino, 750 - Vila Oficinas',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Camargo',
    phone: '3361-2501',
    address: 'Rua Pedro Violani, 364 - Cajuru',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Iracema',
    phone: '3266-2123',
    address: 'Rua Professor Nivaldo Braga, 1571 - Capão da Imbuia',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Lotiguaçu',
    phone: '3364-5498',
    address: 'Rua Eunice Betini Bartoszeck, 1287 - Uberaba',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Salgado Filho',
    phone: '3217-1231',
    address: 'Avenida Senador Salgado Filho, 5265 Uberaba',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US São Domingos',
    phone: '3361-2504',
    address: 'Rua Ladislau Mikosz, 133 Cajuru',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US São Paulo',
    phone: '3217-1221',
    address: 'Rua Canal Belém , 6427 Uberaba',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Solitude',
    phone: '3361-2512',
    address: 'Rua Isaias Ferreira da Silva, 150 - Solitude',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Trindade',
    phone: '3361-2348',
    address: 'Rua Roraima, 1790 - Vila Oficinas',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Trindade 2',
    phone: '3361-2513',
    address: 'Rua Sebastião Marcos Luiz, 1197 - Cajuru',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Uberaba de Cima',
    phone: '3361-2381 e 3361-2380',
    address: 'Rua Capitão Leônidas Marques, 1392 - Uberaba',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Abaeté',
    phone: '3251-1015',
    address: 'Rua Delegado Miguel Zacarias, 403 - Boa Vista',
    businessHours: 'Segunda a sexta das7h às 19h'
  },
  {
    name: 'US Abranches',
    phone: '3354-5447',
    address: 'Rua Aldo Pinheiro, 60 - Abranches',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Atuba ',
    phone: '3315-2292 e 3315-2293',
    address: 'Rua Rio Pelotas, 820 - Bairro Alto',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Bacacheri',
    phone: '3251-1025',
    address: 'Avenida Erasto Gaertner, 797 Bacacheri',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Bairro Alto',
    phone: '3315-2291 e 3315-2281',
    address: 'Rua Jornalista Alceu Chichorro, 314 - Bairro Alto',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Barreirinha',
    phone: '3585-4061',
    address: 'Rua Santa Gema Galgani, 353 - Barreirinha',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Fernando Noronha',
    phone: '3251-1030',
    address: 'Rua João Mequetti, 389 - Santa Cândida',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Jardim Aliança',
    phone: '3015-4560 e 3357-2881',
    address: 'Rua José Ursolino Filho, 646 - Santa Candida',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Santa Cândida',
    phone: '3251-1031 e 3251-1001',
    address: 'Avenida Paraná, 5050 - Santa Cândida',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Santa Efigênia',
    phone: '3355-2982',
    address: 'Rua Voltaire, 139 -Barreirinha',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Tarumã',
    phone: '3315-2294',
    address: 'Rua José Veríssimo, 1352 - Bairro Alto',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Tingui',
    phone: '3251-1000',
    address: 'Rua Nicolau Salomão,671 - Tingui',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Vila Diana',
    phone: '3355-2671',
    address: 'Rua René Descartes, 537 Abranches',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Vila Esperança',
    phone: '3251-1037',
    address: 'Rua Cataratas do Iguaçu, 192 - Atuba',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Vila Leonice',
    phone: '3355-2682 e 3355-2670',
    address: 'Avenida Anita Garibaldi, 6814 - Cachoeira',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Atenas',
    phone: '3285-5266',
    address: 'Rua Emilia Erichsen, 45 - Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Augusta',
    phone: '3373-1630',
    address: 'Rua Robert Redzinski, 921 - Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Barigui',
    phone: '3314-5133',
    address: 'Rua Arthur Martins Franco, 5516 - Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Caiuá',
    phone: '3314-5204',
    address: 'Rua Arnaud Ferreira Vellozo, 200 - Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Campo Alegre',
    phone: '3327-3489 e 3327-3422',
    address: 'Avenida das Industrias, 1749 - Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Cândido Portinari',
    phone: '3314-5208',
    address: 'Rua Durval Leopolpo Landal, 1529 - Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Jardim Gabineto',
    phone: '3285-1333',
    address: 'Rua Engenheiro João Vizinoni, 458 - Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Nossa Senhora da Luz',
    phone: '3212-1455',
    address: 'Rua Emídio Nonato da Silva, 45 Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Oswaldo Cruz',
    phone: '99268-8126',
    address: 'Rua Pedro Gusso, 3749 - Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Sabará',
    phone: '3314-5202',
    address: 'Rua Pedro Andretta, 3030 - Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US São José',
    phone: '3373-6652',
    address: 'Rua Piraí do Sul, 280 - Augusta',
    businessHours: 'Segunda a sexta das 7h às 17h'
  },
  {
    name: 'US São Miguel',
    phone: '99962-0263',
    address: 'Rua Des. CId Campelo, 8060 - Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Taiz Viviane Machado',
    phone: '99996-6546',
    address: 'Rua Gastão Natal Simone, 05 Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Tancredo Neves',
    phone: '3314-5131',
    address: 'Rua Professora Hilda Hank Gonçalves, 435 - Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Vila Sandra',
    phone: '99690-4440',
    address: 'Rua Araranguá, 189 - Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Vila Verde',
    phone: '3212-1522',
    address: 'Rua Emílio Romani, 2684 - Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Vitória Régia',
    phone: '98535-0763',
    address:
      'Rua Paul Garfunkel, 2000 (esq c/ Cyro Correia Pereira) - Cidade Industrial',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Capanema',
    phone: '3218-2400',
    address: 'Rua Manoel Martins de Abreu, 830 - Prado Velho',
    businessHours: 'Segunda a sexta-feira das 7h às 17h'
  },
  {
    name: 'US Mãe Curitibana',
    phone: '3321-2714 e 3321-3209',
    address: 'Rua Jaime Reis, 331 - Alto do São Francisco',
    businessHours: 'Segunda a sexta-feira das 7h às 19h'
  },
  {
    name: 'US Ouvidor Pardinho',
    phone: '3321-2747 e 3321-2751',
    address: 'Rua 24 de Maio, 807 - Praça Ouvidor Pardinho',
    businessHours: 'Segunda a sexta-feira das 7h às 19h'
  },
  {
    name: 'US Aurora',
    phone: '3212-1460',
    address: 'Rua Theofhilo Mansur, 500 - Novo Mundo',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Concórdia',
    phone: '3227-4141',
    address: 'Rua Dilermando Pereira de Almeida, 700 - Pinheirinho',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Fanny Lindóia',
    phone: '3212-1453',
    address: 'Rua Conde dos Arcos, 295 Lindóia',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Ipiranga',
    phone: '3212-1519',
    address: 'Rua Santa Regina, 667 - Capão Raso',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Maria Angélica',
    phone: '3212-1540',
    address: 'Rua Professor Júlio Teodorico Guimarães, 337 Pinheirinho',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Parque Industrial',
    phone: '3212-1424',
    address: 'Rua Laudelino Ferreira Lopes, 1801 Capão Raso',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Sagrado Coração',
    phone: '3227-4233',
    address: 'Rua Antônio Claudino, 375 - Pinheirinho',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Vila Clarice',
    phone: '99267-3635',
    address: 'Rua Eloy de Assis Fabris, 634 - Novo Mundo',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Vila Feliz',
    phone: '3212-1416',
    address: 'Rua Pedro Gusso, 866 - Novo Mundo',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Vila Leão',
    phone: '3212-1475',
    address: 'Rua Primo Lourenço Tosin, 21 - Novo Mundo',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Vila Machado',
    phone: '3212-1411',
    address: 'Rua Laudelino Ferreira Lopes, 2959 Pinheirinho',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Estrela ',
    phone: '3314-5129',
    address: 'Rua Francisco Nowotarski, 204 - Fazendinha',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Parolin',
    phone: '3213-1379',
    address: 'Rua Sergipe, 59 - Vila Guaíra',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Santa Amélia',
    phone: '3329-1930',
    address: 'Rua Berta Klemtz, 215 - Fazendinha',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Santa Quitéria 1',
    phone: '3314-5231',
    address: 'Rua Divina Providência, 1445 - Santa Quitéria',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Santa Quitéria 2',
    phone: '3314-5158',
    address: 'Rua Bocaíuva, 310 - Santa Quitéria',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Santos Andrade',
    phone: '3314-5236',
    address: 'Rua Nelson Ferreira da Luz,145 - Campo Comprido',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Vila Guaíra',
    phone: '3213-7600 e 3213-7591',
    address: 'Rua São Paulo, 1495 - Vila Guaíra',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Bom Pastor',
    phone: '3240-1304 e 3240-1129',
    address: 'Rua José Casagrande, 220 - Vista Alegre',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Butiatuvinha',
    phone: '3372-4405 e 3372-4406',
    address: 'Avenida Manoel Ribas, 8640 - Butiatuvinha',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Campina do Siqueira',
    phone: '3240-1137,  3240-1138 e 3336-1647 ',
    address: 'Rua General Mário Tourinho, 1684 Campina do Siqueira',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Nova Órleans',
    phone: '3370-1584',
    address: 'Avenida Vereador Toaldo Tulio, 4577 -Orleans',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Pilarzinho',
    phone: '3240-1314',
    address: 'Rua Amauri Lange Silvério, 1251 - Pilarzinho',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US São Braz',
    phone: '3272-3459 e 3372-4776',
    address: 'Rua Antonio Escorsin, 1960 - São Braz',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Santa Felicidade',
    phone: '3370-1570',
    address: 'Via Veneto, 10 - Santa Felicidade',
    businessHours: 'Segunda a sexta das 7h às 22h'
  },
  {
    name: 'US Vista Alegre',
    phone: '3240-1303',
    address: 'Rua Miguel de Lazari, 85 - Pilarzinho',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US União das Vilas',
    phone: '3370-1580',
    address: 'Rua Frederico Escorsin, 314 São Braz',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Caximba',
    phone: '3265-1869',
    address: 'Rua Delegado Bruno de Almeida, 7881 - Caximba',
    businessHours: 'Segunda a sexta das 7h às 17h'
  },
  {
    name: 'US Dom Bosco',
    phone: '3396-4949',
    address: 'Rua Angelo Tosin, 100 - Campo do Santana',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Monteiro Lobato',
    phone: '3396-5366',
    address: 'Rua Olivio José Rosetti, 538 -Tatuquara',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Moradias da Ordem',
    phone: '3396-2056',
    address: 'Rua Jovenilson Americo de Oliveira, 240 Tatuquara',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Palmeiras',
    phone: '3348-1721',
    address: 'R. João Batista Burbelo, 12 - Tatuquara',
    businessHours: 'Segunda a sexta das 7h às 17h'
  },
  {
    name: 'US Pompéia',
    phone: '3396-5967',
    address: 'R. João Batista Bettega Jr. s/n / 125 Tatuquara',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Rio Bonito',
    phone: '3396-5624',
    address: 'R. Fanny Bertoldi, 170 - Campo do Santana',
    businessHours: 'Segunda a sexta das 7h às 19h'
  },
  {
    name: 'US Santa Rita',
    phone: '3265-6935',
    address: 'R. Adriana Ceres Zago Bueno, 1350 - Tatuquara',
    businessHours: 'Segunda a sexta das 7h às 19h'
  }
]
