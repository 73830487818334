import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga4'

ReactGA.initialize('G-E68TZ3CBPD')

const Diabetes = () => {
  const navigate = useHistory()
  const [isOpenModal, setIsOpenModal] = useState(false)

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/diabetes',
      title: 'Diabetes'
    })
  }, [])

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  return (
    <div className="container-page diabetes">
      <div className="content-page">
        <div className="header">
          <h1>DIABETES</h1>
          <h6>Principais Suspeitas</h6>
          <p>
            Sinais abaixo podem aparecer em conjunto ou isoladamente. Se os
            sinais forem frequentes é necessário procurar atendimento médico.
          </p>
        </div>

        <ul className="list-symptoms">
          <li>
            <span className="description">SEDE INTENSA</span>
            <span className="icon">
              <img src="/imgs/diabetes/sede.png" alt="SEDE INTENSA" />
            </span>
          </li>
          <li>
            <span className="icon">
              <img src="/imgs/diabetes/fome.png" alt="FOME INTENSA" />
            </span>
            <span className="description">FOME INTENSA</span>
          </li>
          <li>
            <span className="description">
              CICATRIZAÇÃO DIFÍCIL E INFECÇÕES NA PELE
            </span>
            <span className="icon">
              <img
                src="/imgs/diabetes/cicatrizacao.png"
                alt=" CICATRIZAÇÃO DIFÍCIL E INFECÇÕES NA PELE"
              />
            </span>
          </li>
          <li>
            <span className="icon">
              <img src="/imgs/diabetes/perda.png" alt="PERDA DE PESO" />
            </span>
            <span className="description">PERDA DE PESO</span>
          </li>
          <li>
            <span className="description">FAMILIARES COM DIABETES</span>
            <span className="icon">
              <img
                src="/imgs/diabetes/familiares.png"
                alt="FAMILIARES COM DIABETES"
              />
            </span>
          </li>
          <li>
            <span className="icon">
              <img src="/imgs/diabetes/obesidade.png" alt="OBESIDADE" />
            </span>
            <span className="description">OBESIDADE</span>
          </li>
          <li>
            <span className="description">IMPOTÊNCIA SEXUAL</span>
            <span className="icon">
              <img
                src="/imgs/diabetes/impotencia.png"
                alt="IMPOTÊNCIA SEXUAL"
              />
            </span>
          </li>
          <li>
            <span className="icon">
              <img
                src="/imgs/diabetes/pressao.png"
                alt="PRESSÃO ARTERIAL ALTA"
              />
            </span>
            <span className="description">PRESSÃO ARTERIAL ALTA</span>
          </li>
          <li>
            <span className="description">
              URINAR MUITAS VEZES E EM GRANDE QUANTIDADE
            </span>
            <span className="icon">
              <img
                src="/imgs/diabetes/urinar.png"
                alt=" URINAR MUITAS VEZES E EM GRANDE QUANTIDADE"
              />
            </span>
          </li>
          <li>
            <span className="icon">
              <img src="/imgs/diabetes/cansaco.png" alt="CANSAÇO E DESÂNIMO" />
            </span>
            <span className="description">CANSAÇO E DESÂNIMO</span>
          </li>
        </ul>

        <div className="footer">
          <button
            className="button"
            onClick={() => {
              ReactGA.event({
                category: 'Video',
                action: `Click Video Diabetes`,
                label: `Video Diabetes`
              })

              setIsOpenModal(true)
            }}
          >
            Assita o vídeo
          </button>

          <button
            className="button second-button"
            onClick={() => {
              navigate.push({
                pathname: '/diabetes-how-diagnose'
              })
              window.scrollTo(0, 0)
            }}
          >
            Como diagnosticar
          </button>
        </div>
      </div>

      {isOpenModal && (
        <div>
          <div
            onClick={() => {
              setIsOpenModal(false)
            }}
            className="background-modal"
          ></div>
          <div className="modal-video">
            <button onClick={toggleModal} className="close-modal">
              &times;
            </button>
            <video
              controls
              autoplay={false}
              style={{
                width: '100%',
                borderRadius: '5px'
              }}
            >
              <source
                src="https://s3.us-east-1.amazonaws.com/interatividade.vocenarpc.com.br/teste-video/video/EXTRA+APP+NUTRICIONISTA.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </div>
  )
}

export default Diabetes
